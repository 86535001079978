import React, { Component } from 'react';
import Modal from 'react-modal';
import 'react-datepicker/dist/react-datepicker.css';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import CBMediaQuery from '../shared/CBMediaQuery';
import { getStyle, getModalStyle, SERVICE_WORKSHOP } from '../../utils/utils';
import CBButtonSubmit from '../shared/CBButtonSubmit';
import CBInputField from '../shared/form_components/CBInputField';
import {
  fetchWSVehicle as _fetchWSVehicle,
  createWSVehicle as _createWSVehicle,
  updateWSVehicle as _updateWSVehicle,
} from '../../actions/vehicleActions';
import { getLengthValidator, getEmailValidator, getPhoneValidator } from '../../utils/validators';
import { browserIsIE } from '../../ie-support';

class AddEditVehicleModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
      },
      formValid: {
        registration_number: false,
        owner: false,
      },
    };
  }

  componentDidMount = () => {
    const { service, selectedWorkshopId, vehicle, wsVehicle } = this.props;
    let initialFormData;
    let initialFormValid;
    if (vehicle) {
      initialFormData = {
        registration_number: vehicle.registration_number,
        owner: wsVehicle && wsVehicle.unregistered_owner && wsVehicle.unregistered_owner.name,
        email: wsVehicle && wsVehicle.unregistered_owner && wsVehicle.unregistered_owner.email,
        phone_number: wsVehicle && wsVehicle.unregistered_owner && wsVehicle.unregistered_owner.phone_number,
      };
      initialFormValid = {
        registration_number: !!initialFormData.registration_number,
        owner: !!initialFormData.owner,
      };
    } else {
      initialFormData = {};
      initialFormValid = {
        registration_number: false,
        owner: false,
      };
    }
    this.setState({
      formData: {
        workshop_id: selectedWorkshopId,
        ...initialFormData,
      },
      formValid: initialFormValid,
    });
  };

  setIsValid = (name, isValid) => {
    const { formValid } = this.state;
    const newFormValid = {
      ...formValid,
      [name]: isValid,
    };

    this.setState({ formValid: newFormValid });
  };

  getIsValid = () => {
    const { formValid } = this.state;
    let isValid = true;
    Object.values(formValid).forEach(fieldIsValid => {
      if (!fieldIsValid) {
        isValid = false;
      }
    });
    return isValid;
  };

  updateFormState = changedObject => {
    const { target, value } = changedObject;
    let newValue = value;
    if (target === 'registration_number') {
      newValue = newValue.toUpperCase();
      const regex = /[^A-Z-ÅÄÖ0-9]/g;
      newValue = newValue.replace(regex, '');
    }
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[target] = newValue;
    this.setState({ formData: newFormData });
  };

  submitForm = () => {
    const { formData } = this.state;
    const {
      closeModal,
      createWSVehicle,
      updateWSVehicle,
      vehicle,
      selectedWorkshopId,
    } = this.props;
    const newFormData = Object.assign({}, formData, { workshop_id: selectedWorkshopId });

    if (vehicle) {
      updateWSVehicle(newFormData, vehicle.id);
    } else {
      createWSVehicle(newFormData);
    }

    closeModal();
  };


  render() {
    const { formData } = this.state;
    const {
      modalIsOpen, closeModal, afterOpenModal, t, vehicle,
    } = this.props;
    return (
      <CBMediaQuery>
        {screenSize => (
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={getModalStyle(screenSize, styles.modal)}
            contentLabel='Example Modal'
          >
            <div style={browserIsIE() ? getStyle(screenSize, styles, 'containerIE') : getStyle(screenSize, styles, 'container')}>
              <img
                src='/img/icon_close.svg'
                alt='close'
                style={getStyle(screenSize, styles, 'closeIcon')}
                onClick={closeModal}
              />
              <p style={getStyle(screenSize, styles, 'title')}>
                {!vehicle ? t('addVehicleInfoHeading') : t('editVehicleInfoHeading')}
              </p>
              <CBInputField
                name='registration_number'
                inputStyle={browserIsIE() ? getStyle(screenSize, styles, 'inputIE') : getStyle(screenSize, styles, 'input')}
                value={formData.registration_number}
                placeholder='ABC-123'
                onChange={this.updateFormState}
                validators={[getLengthValidator(4, 7)]}
                isValidCallback={this.setIsValid}
                labelText={t('addCarModalRegNumberHeading')}
                labelTextStyle={getStyle(screenSize, styles, 'subTitle')}
                disabled={!!vehicle}
              />
              <span style={getStyle(screenSize, styles, 'divider')} />
              <CBInputField
                name='owner'
                inputStyle={browserIsIE() ? getStyle(screenSize, styles, 'inputIE') : getStyle(screenSize, styles, 'input')}
                value={formData.owner}
                onChange={this.updateFormState}
                validators={[getLengthValidator(1, 200)]}
                isValidCallback={this.setIsValid}
                labelText={t('ownerLabel')}
                labelTextStyle={getStyle(screenSize, styles, 'subTitle')}
                autoComplete={false}
              />
              <CBInputField
                name='email'
                inputStyle={browserIsIE() ? getStyle(screenSize, styles, 'inputIE') : getStyle(screenSize, styles, 'input')}
                value={formData.email}
                onChange={this.updateFormState}
                validators={[getEmailValidator()]}
                isValidCallback={this.setIsValid}
                labelText={t('emailLabel')}
                labelTextStyle={getStyle(screenSize, styles, 'subTitle')}
                autoComplete={false}
              />
              <CBInputField
                name='phone_number'
                inputStyle={browserIsIE() ? getStyle(screenSize, styles, 'inputIE') : getStyle(screenSize, styles, 'input')}
                value={formData.phone_number}
                onChange={this.updateFormState}
                validators={[getPhoneValidator()]}
                isValidCallback={this.setIsValid}
                labelText={t('phoneNumberLabel')}
                labelTextStyle={getStyle(screenSize, styles, 'subTitle')}
                autoComplete={false}
              />
              <span style={getStyle(screenSize, styles, 'spacer')} />
              <CBButtonSubmit
                disabled={!this.getIsValid()}
                onClick={this.submitForm}
                text={t('saveVehicleButton')}
              />
            </div>
          </Modal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    closeIcon: {
      position: 'absolute',
      top: 8,
      right: 8,
      opacity: 0.2,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
    },
    containerIE: {
      display: 'unset',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
    },
    title: {
      fontSize: 32,
      color: '#0095da',
      textAlign: 'center',
    },
    subTitle: {
      fontSize: 14,
      color: 'black',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    icon: {
      width: 540,
      height: 1,
      opacity: .3,
      // marginTop: 25,
      marginBottom: 14,
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      width: '90%',
      border: '0',
      borderRadius: 100,
      height: 40,
      outlineStyle: 'none',
    },
    inputIE: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      width: '90%',
      border: '0',
      borderRadius: 100,
      outlineStyle: 'none',
      padding: 8,
    },
    info: {
      width: '100%',
      fontSize: 14,
      color: 'black',
      paddingHorizontal: 30,
      textAlign: 'center',
    },
    divider: {
      height: 1,
      width: '90%',
      borderBottom: '1px solid #666',
      opacity: 0.3,
      marginTop: 15,
      marginBottom: 15,
    },
    spacer: {
      marginBottom: 30,
    },
  },
  small: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      width: 200,
      resize: 'none',
      color: 'black',
      height: 30,
    },
  },
  modal: {
    default: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        maxHeight: '95vh',
      },
      overlay: {
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
    small: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
      },
      overlay: {
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
  },
};

function mapStateToProps(state) {
  return {
    vehiclePermissionRequest: state.vehiclePermission,
    user: state.authUser.user,
    wsVehicle: state.vehicle.wsVehicle,
    selectedWorkshopId: state.workshop.selectedWorkshop,
    service: state.app.service,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchWSVehicle: _fetchWSVehicle,
    createWSVehicle: _createWSVehicle,
    updateWSVehicle: _updateWSVehicle,
  },
)(translate('WSHomeView')(AddEditVehicleModal));
