import React from 'react';
import { translate } from 'react-i18next';
import { SERVICE_ADMIN, SERVICE_FLEET, SERVICE_PERSONAL } from '../../../utils/utils';
import UserSettingsMenuItem from './UserSettingsMenuItem';

class UserSettingsMenu extends React.Component {
  state = {
    activeTab: 'Profile',
  };

  render() {
    const { t, user, staff, service } = this.props;
    const { activeTab } = this.state;
    return (
      <div style={styles.settingsMenuContainer}>
        <div style={styles.sectionContainer}>
          {!staff && (
            <React.Fragment>
              <UserSettingsMenuItem
                alt='Profile'
                to='/settings/profile'
                text={t('userInfoMenu')}
                setActiveTab={tabName => this.setState({ activeTab: tabName })}
                activeTab={activeTab}
              />
              {
                (service === SERVICE_PERSONAL) && (
                  <UserSettingsMenuItem
                    alt='Cars'
                    to='/settings/cars'
                    text={t('carsMenu')}
                    setActiveTab={tabName => this.setState({ activeTab: tabName })}
                    activeTab={activeTab}
                  />
                )
              }
              {
                // Hidden 30.5.2024
                (service === SERVICE_PERSONAL) && false && (
                  <UserSettingsMenuItem
                    alt='Workshops'
                    to='/settings/workshops'
                    text={t('workshopsMenu')}
                    setActiveTab={tabName => this.setState({ activeTab: tabName })}
                    activeTab={activeTab}
                  />
                )
              }
              {
                (service === SERVICE_ADMIN || service === SERVICE_FLEET) && (
                  <UserSettingsMenuItem
                    alt='Organizations'
                    to='/settings/organizations'
                    text={service === SERVICE_FLEET ? t('fleetOrganizationMenu') : t('staffOrganizationsMenu')}
                    setActiveTab={tabName => this.setState({ activeTab: tabName })}
                    activeTab={activeTab}
                  />
                )
              }
              <UserSettingsMenuItem
                alt='Reminders'
                to='/settings/reminders'
                text={t('remindersMenu')}
                setActiveTab={tabName => this.setState({ activeTab: tabName })}
                activeTab={activeTab}
              />

              {user.profile.has_subscriptions && (
                <UserSettingsMenuItem
                  alt='Subscriptions'
                  href='/api/1/payments/stripe-create-portal-session/'
                  text={t('subscriptionsMenu')}
                  setActiveTab={tabName => this.setState({ activeTab: tabName })}
                  activeTab={activeTab}
                />
              )}
            </React.Fragment>
          )}
          {staff && (
            <UserSettingsMenuItem
              alt='StaffWorkshops'
              to='/staff_settings/staff_workshops'
              text={t('staffWorkshopsMenu')}
              setActiveTab={tabName => this.setState({ activeTab: tabName })}
            />
          )}
          {staff && (
            <UserSettingsMenuItem
              alt='StaffUsers'
              to='/staff_settings/users'
              text={t('staffUsersMenu')}
              setActiveTab={tabName => this.setState({ activeTab: tabName })}
            />
          )}
          {staff && (
            <UserSettingsMenuItem
              alt='StaffOrganizations'
              to='/staff_settings/organizations'
              text={t('staffOrganizationsMenu')}
              setActiveTab={tabName => this.setState({ activeTab: tabName })}
            />
          )}
          {staff && service === SERVICE_ADMIN && (
            <UserSettingsMenuItem
              alt='StaffStatistics'
              to='/staff_settings/staff_stats'
              text={t('staffStatistics')}
              setActiveTab={tabName => this.setState({ activeTab: tabName })}
            />
          )}
          {staff && service === SERVICE_ADMIN && (
            <UserSettingsMenuItem
              alt='StaffAdvertisements'
              to='/staff_settings/advertisements'
              text={t('staffAdvertisements')}
              setActiveTab={tabName => this.setState({ activeTab: tabName })}
            />
          )}
          {staff && service === SERVICE_ADMIN && (
            <React.Fragment>
              <UserSettingsMenuItem
                alt='StaffPricing'
                to='/staff_settings/pricing'
                text={t('staffPricing')}
                setActiveTab={tabName => this.setState({ activeTab: tabName })}
              />
              <UserSettingsMenuItem
                alt='StaffInvoiceReports'
                to='/staff_settings/invoice-reports'
                text={t('staffInvoiceReports')}
                setActiveTab={tabName => this.setState({ activeTab: tabName })}
              />
            </React.Fragment>
          )}
        </div>
        {((service === SERVICE_ADMIN || service === SERVICE_FLEET)) && (
          <div style={styles.sectionContainer}>
            <UserSettingsMenuItem
              alt='OrganizationPermissions'
              to={user.is_staff && service === SERVICE_ADMIN ? '/staff_settings/organization-permissions' : '/settings/organization-permissions'}
              text={t('organizationPermissions')}
              setActiveTab={tabName => this.setState({ activeTab: tabName })}
            />
            <UserSettingsMenuItem
              alt='GaragePermissions'
              to={user.is_staff && service === SERVICE_ADMIN ? '/staff_settings/garage-permissions' : '/settings/garage-permissions'}
              text={t('organizationGaragePermissions')}
              setActiveTab={tabName => this.setState({ activeTab: tabName })}
            />
            <UserSettingsMenuItem
              alt='OrgVehiclePermissions'
              to={user.is_staff && service === SERVICE_ADMIN ? '/staff_settings/organization-vehicle-permissions' : '/settings/organization-vehicle-permissions'}
              text={t('organizationVehiclePermissions')}
              setActiveTab={tabName => this.setState({ activeTab: tabName })}
            />
            <UserSettingsMenuItem
              alt='OrgCareServices'
              to={user.is_staff && service === SERVICE_ADMIN ? '/staff_settings/care-services' : '/settings/care-services'}
              text={t('careServicesHeading')}
              setActiveTab={tabName => this.setState({ activeTab: tabName })}
            />
            {((service === SERVICE_ADMIN)) && (
              <UserSettingsMenuItem
                alt='Workshop permissions'
                to={'/staff_settings/workshop-permissions'}
                text={t('workshopPermissionsHeading')}
                setActiveTab={tabName => this.setState({ activeTab: tabName })}
              />
            )}
            <UserSettingsMenuItem
              alt='Check lists'
              to={user.is_staff && service === SERVICE_ADMIN ? '/staff_settings/check-lists' : '/settings/check-lists'}
              text={t('checkListsHeading')}
              setActiveTab={tabName => this.setState({ activeTab: tabName })}
            />
            <UserSettingsMenuItem
              alt='Organization vehicle reminders'
              to={user.is_staff && service === SERVICE_ADMIN ? '/staff_settings/org-vehicle-reminders' : '/settings/org-vehicle-reminders'}
              text={t('organizationVehicleRemindersHeading')}
              setActiveTab={tabName => this.setState({ activeTab: tabName })}
            />
          </div>
        )}
        {!staff && (
          <div style={styles.sectionContainer}>
            {/* <UserSettingsMenuItem
              alt='Instructions'
              to='/settings/instructions'
              text={t('instructionsMenu')}
              setActiveTab={tabName => this.setState({ activeTab: tabName })}
              activeTab={activeTab}
            /> */}
            <UserSettingsMenuItem
              href="/accounts/terms-and-conditions"
              text={t('termsMenu')}
            />
          </div>
        )}
      </div>
    );
  }
}

const styles = {
  sectionContainer: {
    borderBottom: '1px solid rgba(255, 255, 255, 0.4)',
  },
  settingsMenuContainer: {},
  helpContainer: {
    borderTop: '1px solid rgba(255, 255, 255, 0.4)',
    borderBottom: '1px solid rgba(255, 255, 255, 0.4)',
  },
};

export default translate('SettingsView')(UserSettingsMenu);
