import React from 'react';
import { Link } from 'react-router-dom';

class UserSettingsMenuItem extends React.Component {
  render() {
    const {
      href,
      to,
      text,
      alt,
      activeTab,
      setActiveTab,
    } = this.props;

    return (
      <div style={styles.settingsLinkContainer}>
        {!href && (
          <Link style={styles.linkWrapper} onClick={() => setActiveTab(alt)} to={to}>
            <p style={alt === activeTab ? styles.activeLinkText : styles.linkText}>{text}</p>
          </Link>
        )}
        {!!href && (
          <a style={styles.linkWrapper} href={href}>
            <p style={styles.linkText}>{text}</p>
          </a>
        )}
      </div>
    );
  }
}

const styles = {
  activeLinkText: {
    opacity: .5,
  },
  settingsLinkContainer: {
    paddingLeft: 16,
    paddingTop: 8,
  },
};

export default UserSettingsMenuItem;
